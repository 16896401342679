<template>
  <div>
    <div class="ant-list ant-list-split">
      <div class="ant-spin-nested-loading">
        <div class="ant-spin-container" v-if="loaded">
          <div v-for="item in data" :key="item.month">
            <h4>{{ capitalizeFirstLetter(item.month) }}</h4>
            <ul class="ant-list-items" v-if="item.children">
              <li v-for="child in item.children" :key="child.key">
                <div class="ant-list-item">
                <a-list-item-meta :description="'Avdeling: '.concat(child.department)">
                  <a slot="title" @click="goToEmployee(child.uid)">{{ child.name }}</a>
                    <a-avatar
                      slot="avatar"
                      :src="child.imageUrl"
                      icon="user"
                    />
                  </a-list-item-meta>
                  <div>{{ String(moment(child.birthday).format('D')).concat(' ', capitalizeFirstLetter(String(moment(child.birthday).format('MMMM')))) }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from '@/services/firebase'
import { mapState } from 'vuex'
import moment from 'moment'

function onChange(pagination, filters, sorter) {
  console.log('params', pagination, filters, sorter)
}

export default {
  name: 'TablesAntdFilterSorter',
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      data: [],
      loaded: false,
      months: [],
    }
  },
  methods: {
    moment,
    onChange,
    aboutUser(uid) {
      this.$router.push({ path: `/hr/persons/${uid}` })
    },
    calculateAge (dob) {
      const birthday = new Date(dob)
      const diff = Date.now() - birthday.getTime()
      const ageDate = new Date(diff)
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },
    capitalizeFirstLetter(string) {
      const newString = string.charAt(0).toUpperCase() + string.slice(1)
      return newString
    },
    goToEmployee(uid) {
      if (['HRseeUsersAll'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
        this.$router.push({ path: `/hr/person/${uid}` })
      }
    },
  },
  mounted() {
    const months = moment.months()
    console.log(months)
    const data = []
    months.forEach(month => {
      data.push({
        month,
        children: [],
      })
    })
    // console.log(data)
    firebase.firestore.collection('users').where('status', '==', 'active').get()
      .then(snapshot => {
        let key = 0
        snapshot.forEach(doc => {
          const positions = doc.data().positions
          const project = positions ? positions[positions.length - 1].project : ''
          const imageUrl = doc.data().imageUrl
          const month = moment(doc.data().birthdate).format('MMMM')
          const birthday = moment(doc.data().birthdate).format('YYYY-MM-DD')
          const index = data.map(function(e) { return e.month }).indexOf(month)
          if (index >= 0) {
            data[index].children.push({
              key,
              name: doc.data().fullName,
              department: doc.data().department,
              project,
              imageUrl,
              birthday,
              uid: doc.id,
            })
          }
          key += 1
        })
        data.forEach((month, i) => {
          data[i].children = data[i].children.sort((a, b) => moment(a.birthday).format('MMDD') - moment(b.birthday).format('MMDD'))
        })
        const currentMonth = Number(moment().format('MM')) - 1
        const finalData = data.splice(currentMonth, 12).concat(data.splice(0, currentMonth))
        this.data = finalData
        // console.log(finalData)
        this.loaded = true
      })
    // create a search bar on top to filter any value for user const res = arr.filter(obj => Object.values(obj).some(val => val.includes(search)));
    //     let i = 0
    //       // const birthday
    //       const birthday = doc.data().birthdate.substring(8, 10)
    //       // const thisMonth = new Date().getMonth()
    //       // const birthmonth = String(moment(doc.data().birthdate.substring(5, 7) - thisMonth))
    //       // const birthdate = birthmonth.concat('-', birthday)
    //       const imageUrl = doc.data().imageUrl
    //       // this.data.push({
    //       //   key: i,
    //       //   name: doc.data().fullName,
    //       //   department: doc.data().department,
    //       //   project: project,
    //       //   imageUrl,
    //       //   birthdate,
    //       //   birthday,
    //       //   birthmonth,
    //       // })
    //     })
    //     // this.data = this.data.sort((a, b) => moment(a.birthdate).format('MMDD') - moment(b.birthdate).format('MMDD'))
    //   }).finally(() => {
    //     const currentMonth = Number(moment().format('MM')) - 1
    //     console.log(currentMonth)
    //     this.months = this.months.splice(currentMonth, 12).concat(this.months.splice(0, currentMonth))
    //     this.months = this.capitalizeFirstLetter(this.months)
    //     this.loaded = true
    //   })
  },
}
</script>
